body, html {
    margin: 0;
    padding: 0;
    height: 100vh;
    font-family: Arial, sans-serif;
}

#pixi-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.content {
    position: relative;
    z-index: 1;
    color: #fff;
    mix-blend-mode: difference;
    padding: 15px;

    p {
        margin: 0;  
    }
}